import React from 'react'
import intl from 'react-intl-universal'
import http from 'axios'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Header from './Header'
import Footer from './Footer'
import { isDark, brandStyle } from '../utils/Tools'

require('bootstrap/dist/css/bootstrap.min.css')
require('../styles/App.scss')
require('../styles/hc.scss')

const axios = require('axios')

const locales = [
  { name: 'English', value: 'en-US' },
  { name: 'Polski', value: 'pl-PL' },
  { name: 'Deutch', value: 'de-DE' },
  { name: 'Español', value: 'es-ES' },
  { name: 'Čeština', value: 'cs-CZ' },
  { name: 'Slovenčina', value: 'sk-SK' },
]

const mapStateToProps = state => ({
  authenticated: state.auth.session.id !== false,
  lang: state.router.location.query.lang
})

class AppComponent extends React.Component {

  constructor() {
    super()
    this.state = {
      initDone: false
    }
    let conf = window.ODOO
    if (typeof conf == 'undefined'){
      conf = {
        url: window.location.hostname.replace('podsumowanie', 'gateway'),
        port: ''
      }
    }

    if(document.querySelector('.logoStyle') === null){
      let logo = document.createElement('style')
      logo.classList.add('logoStyle')
      logo.innerHTML +=`
        #root header .logo{
          background-image: url(${conf.url + (conf.port?':'+conf.port:'')}/web/binary/company_logo)
        }
        `
      document.getElementsByTagName('head')[0].appendChild(logo)
    }
    if(document.querySelector('.brandStyle') === null){
      let brandUrl = conf.url + (conf.port?':'+conf.port:'')+'/brand/'
      axios.get(brandUrl).then((response) => {
        if(response.status === 200){
          let kolor = response.data
          let style = document.createElement('style')
          style.classList.add('brandStyle')
          let kolor_text = '#000'
          if(isDark(kolor)){
            kolor_text = '#fff'
          }
          if (kolor){
            style.innerHTML = brandStyle(kolor, kolor_text)
          }
          document.getElementsByTagName('head')[0].appendChild(style)
        }
      }).catch(() => {
        // no brand data - do nothing
      })
    }
    let spinner = document.querySelector('.loaderWrap')
    if (spinner) spinner.style.display = 'none'
  }

  componentDidMount(){
    this.loadLocales()
  }

  componentDidUpdate(){
    const {lang} = this.props
    if (lang && (intl.options.currentLocale !== lang)){
      window.location.reload()
    }
  }

  loadLocales() {
    const {lang} = this.props
    let currentLocale = lang || intl.determineLocale({
      localStorageLocaleKey: 'lang'
    })
    if (!locales.find((l)=>{return l.value === currentLocale })) {
      currentLocale = 'pl-PL'
    }

    http.get(`translations/${currentLocale}.json`).then(res => {
      // init method will load CLDR locale data according to currentLocale
      return intl.init({
        currentLocale,
        locales: { [currentLocale]: res.data }
      })
    }).then(() => {
      // After loading CLDR locale data, start to render
      this.setState({ initDone: true });
      document.querySelector('.loaderWrap').style.display = 'none'
    })
  }

  render() {
    const {authenticated, children} = this.props
    const {initDone} = this.state
    return (
      initDone &&
      <article>
        <Header authenticated={authenticated}/>
        <main>
          {children}
        </main>
        <Footer locales={locales}/>
      </article>
    )
  }
}
const App = connect(mapStateToProps)(AppComponent)

export default withRouter(App)
